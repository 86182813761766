import Notify from './Notify.js';
import Sleep from './Sleep.js';
import { Router } from '../Router.js';

class FetchRequest {
  constructor(url, options = {}, withToken = false) {
    this.url = url;
    this.options = options;
    this.withToken = withToken;
    this.installController();
  }

  installController() {
    if (this.options.method !=='POST') return;
    //console.log('setupControler', this.url, this.options);
    this.controller = new AbortController();
    this.options.signal = this.controller.signal;
    this.onAbort = this.onAbort.bind(this);

    // emitted by UrlsParams page parameters change 
    window.addEventListener('abort-pending-xhr', this.onAbort);

    // emitted by Vaadin router when page change 
    window.addEventListener('vaadin-router-location-changed', this.onAbort);
  }

  onAbort(ev) {
    //console.log('onAbort', this.url, ev);
    this.controller.abort();
    this.uninstallController();
  }

  uninstallController() {
    if (!this.controller) return;
    //console.log('uninstallController', this.url);
    window.removeEventListener('abort-pending-xhr', this.onAbort);
    window.removeEventListener('vaadin-router-location-changed', this.onAbort);
    this.controller = null;
  }


  async execute() {
    let attempts = 0;

    while (attempts < 3) {
      if (this.withToken) {
        const token = await this.addToken();
        if (!token) {
          attempts++;
          await Sleep(300);
          continue;
        }
      }

      try {
        const response = await fetch(this.url, this.options);
        this.uninstallController();
        return await this.handleResponse(response);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.warn('user cancel request');
          return; // Ne pas lever d'erreur si l'annulation est intentionnelle
        }
        console.error('FetchRequest: Error during fetch operation', error);
        throw error;
      }
    }

    throw new Error('FetchRequest: Failed after 3 attempts');
  }

  async addToken() {
    if (!this.options.headers) {
      this.options.headers = {};
    }
    this.options.headers['Content-Type'] = 'application/json';

    const token = await FetchManager.getToken();
    if (!token) return;

    if (this.options.body) {
      const data = JSON.parse(this.options.body);
      data.csrfToken = token;
      this.options.body = JSON.stringify(data);
    } else {
      this.options.body = JSON.stringify({ csrfToken: token });
    }
    return token;
  }

  async handleResponse(response) {
    // Gestion des erreurs de réponse (503, 404, etc.)
    if (response.status === 503) {
      Router.go('/public/errors/503');
      return;
    }

    if (response.status === 404) {
      Notify.errorCode('EHTTP_404', this.url);
      return;
    }

    if (response.status === 405) {
      Notify.errorCode('EHTTP_405', this.url);
      return;
    }

    if (response.status === 504) {
      Notify.errorCode('EHTTP_504', this.url);
      return;
    }

    const json = await response.json();

    if (response.status !== 200) {
      if (json.error) {
        return this.handleError(json);
      }
      throw new Error('unknown error', json);
    }

    return json;
  }

  handleError(json) {
    if (json.error === 'EVALIDATION_FAILED') {
      return json;
    }

    if (json.error === 'ETOKEN_SESSION_NOT_ACTIVE') {
      const event = new CustomEvent('session-expired');
      window.dispatchEvent(event);
      return;
    }

    Notify.errorCode(json.error, this.url, json.detail);

    if (json.error === 'EREFRESH_TOKEN_ERROR') {
      setTimeout(() => {
        console.log('Supposed to reload the page');
      }, 5000);
    }
  }
}

class FetchManager {
  constructor() {
    this.basePath = '/api/v2';
    this.queue = [];
    this.isProcessing = false;
    this.token = null;
  }

  // Méthode pour gérer la queue des requêtes
  async processQueue() {
    if (this.isProcessing || this.queue.length === 0) {
      return;
    }

    this.isProcessing = true;

    while (this.queue.length > 0) {
      const { fetchRequest, resolve, reject } = this.queue.shift();
      try {
        const response = await fetchRequest.execute();
        resolve(response); // Résoudre la promesse après traitement
      } catch (error) {
        reject(error); // Rejeter en cas d'erreur
      }
    }

    this.isProcessing = false;
  }

  // Ajout d'une requête à la queue
  queueRequest(url, options, withToken = false) {
    return new Promise((resolve, reject) => {
      const fetchRequest = new FetchRequest(this.buildUrl(url), options, withToken);
      this.queue.push({ fetchRequest, resolve, reject });
      this.processQueue(); // Lancer le traitement de la queue
    });
  }

  buildUrl(url) {
    return url[0] !== '/' ? `${this.basePath}/${url}` : url;
  }

  async get(url) {
    return await this.queueRequest(url, { method: 'GET' });
  }

  async post(url, data = {}) {
    return await this.queueRequest(url, {
      method: 'POST',
      body: JSON.stringify(data),
    }, true); // Requête avec token
  }

  async delete(url, data = {}) {
    return await this.queueRequest(url, {
      method: 'DELETE',
      body: JSON.stringify(data),
    }, true);
  }

  async put(url, data = {}) {
    return await this.queueRequest(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    }, true);
  }

  async search(url, data = {}) {
    return await this.queueRequest(url, {
      method: 'X-SEARCH',
      body: JSON.stringify(data),
    }, true);
  }

  async upload(url, formData) {
    return await this.queueRequest(url, {
      method: 'POST',
      body: formData,
    });
  }

  async postOrPut(url, data = {}, id) {
    if (id) return await this.put(`${url}/${id}`, data);
    return await this.post(url, data);
  }

  // Récupération d'un token CSRF
  static async getToken() {
    const url = '/api/v2/public/token';
    const response = await fetch(url);
    const json = await response.json();
    return json?.tok;
  }

  sendBeacon(url, data) {
    url = this.buildUrl(url);
    const jsonData = JSON.stringify(data);
    const success = navigator.sendBeacon(url, new Blob([jsonData], { type: 'application/json' }));
    if (!success) {
      console.error('Beacon failed to send');
    }
  }
}

// Export de l'instance de FetchManager
const fetchManager = new FetchManager();
export default fetchManager;
